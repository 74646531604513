/* @import url("https://fonts.googleapis.com/icon?family=Material+Icons"); */
@import url("https://fonts.cdnfonts.com/css/caros");
/* @import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap'); */
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;700&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  .overlay {
    @apply absolute top-0 z-10 h-[100%] w-[100%] bg-cover;
  }
  .bg {
    @apply absolute top-0 -z-[10] h-[100%] w-[100%] bg-cover;
  }
  .navLink {
    @apply border-secondary border-solid border-t-0 border-r-0 border-l-0 border-b-2 pb-5 w-full text-center;
  }
  #swiper-btn {
    @apply bg-[#d530b061] text-primary  p-2 rounded-full w-10 h-10 text-[29px] flex items-center justify-center cursor-pointer;
  }
}

.swiper-pagination {
  cursor: auto;
}

body {
  @apply leading-[normal] m-0;
  overflow-x: hidden;
}

* {
  font-family: "Inter", sans-serif !important;
  scroll-behavior: smooth;
}

button {
  border: none;
}

a {
  text-decoration: none;
  color: black;
}

#toast-container .toast {
  position: relative;
  animation: toast-anim 0.4s linear forwards;
  width: 100%;
  padding: 19px;
  color: white;
  border-radius: 20px;
  text-transform: capitalize;
  font-weight: 600;
}
