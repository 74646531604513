#GallaryFull .swiper {
  width: 100%;
  height: 100%;
}

#GallaryFull .swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fdfcdc;

  display: flex;
  justify-content: center;
  align-items: center;
}

#GallaryFull .swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

#GallaryFull .swiper {
  width: 100%;
  height: 300px;
  margin-left: auto;
  margin-right: auto;
}

#GallaryFull .swiper-slide {
  background-size: cover;
  background-position: center;
}

.mySwiper2 {
  height: 80%;
  width: 100%;
}

 .mySwiper {
  height: 20%;
  box-sizing: border-box;
  padding: 10px 0;
}

.mySwiper .swiper-slide {
  width: 25%;
  height: 100%;
  opacity: 0.4;
}

.mySwiper .swiper-slide-thumb-active {
  opacity: 1;
}

#GallaryFull .swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.swiper-pagination-fraction,
.swiper-pagination-fraction .swiper-pagination-current,
.swiper-pagination-fraction .swiper-pagination-total {
  color: white;
  padding: 2px 5px;
  background-color: #219dbc;
  width: fit-content;
  border-radius: 20px;
}

.swiper-pagination,
.swiper-pagination-fraction,
.swiper-pagination-horizontal {
  position: absolute !important;
  left: 50% !important;
  transform: translateX(-50%) !important;
}
